import * as React from 'react'
import { PageProps } from 'gatsby'
import { Layout } from '../../components/common/Layout'
import { SEO } from '../../components/common/SEO'
import { ContactForm, ContactHeader } from '../../components/domains/contact'

const ContactPage = (props: PageProps) => {
	return (
		<Layout location={[{ name: 'お問い合わせ', path: '/contact' }]}>
			<SEO title="お問い合わせ" description="" />
			<ContactHeader currentStep={1} />
			<ContactForm location={props.location} />
		</Layout>
	)
}

export default ContactPage
